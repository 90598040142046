<template>
	<S2SForm title="Cash Centres">
		<S2SCard title="Edit Cash Centre">
			<S2SFormGenerator ref="cashcentreForm" :schema="cashcentreSchema" :data="model"></S2SFormGenerator>
			<v-flex class="text-xs-left text-right">
				<v-btn text :to="{ name: 'cash-centres' }" class="mr-2">Cancel</v-btn>
				<v-btn color="accent" @click="updateCentre()" :disabled="status.loading">Update</v-btn>
			</v-flex>
		</S2SCard>
	</S2SForm>
</template>

<script>
import Vue from "vue";
import cashcentreSchema from "@/static/config/forms/cashcentre-schema.json";
import { CashCentreBody } from "../../api/safes-api";

export default Vue.extend({
	name: "CashCentresEdit",

	data: function() {
		return {
			cashcentreSchema: cashcentreSchema,
			model: {}
		};
	},

	computed: {
		cashCentres: function() {
			return this.$store.state.safes.cashCentres;
		},
		status: function() {
			return this.$store.state.safes.status;
		}
	},

	created: function() {
		this.$store.dispatch("safes/fetchCashCentres");
	},

	mounted: function() {
		setTimeout(() => {
			for (let centre of this.cashCentres) {
				if (this.$route.params.centreId == centre.id.toString()) {
					this.model = centre;
				}
			}
		}, 500);
	},

	methods: {
		async updateCentre() {
			const form = this.$refs["cashcentreForm"];
			if (await form.validate()) {
				if (this.$store.dispatch("safes/updateCashCentre", form.model)) {
					this.$router.push({ name: "cash-centres" });
				}
			}
		}
	}
});
</script>
